import React from "react";

import { Button } from "@iwoca/orion";
import { Link } from "react-router-dom";

import mfaHeaderSVG from "../../../assets/mfaHeader.svg";
import styles from "../MultifactorAuth.module.css";

export const SetupMultifacatorAuth = () => {
  return (
    <div className={styles.modal}>
      <img src={mfaHeaderSVG} className={styles.mfaHeader} alt="" />
      <div className={styles.textContainer}>
        <h1 className={styles.headline}>Let’s keep things secure</h1>
        <p className={styles.firstSubtitle}>
          You’ll need to add Multi-factor authentication to link iwocaPay to
          other systems.
        </p>
        <p className={styles.secondSubtitle}>
          It helps keep your data safe by making sure only you can access it.
          You’ll need your phone with you to set it up.
        </p>
        <Link
          to={`/login/two-factor/setup/connect/${window.location.search}`}
          className={styles.noUnderline}
        >
          <Button className={styles.submitButton}>
            Set up multi-factor authentication
          </Button>
        </Link>
      </div>
    </div>
  );
};
