import React from "react";

import { IwocaLogo } from "@iwoca/orion";
import { t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";

import styles from "./TokenContainer.module.css";

type Props = {
  ["data-testid"]?: string;
};
const TokenContainer: React.FunctionComponent<Props> = (props) => {
  const { i18n } = useLingui();
  return (
    <>
      <div className={styles.navBar}>
        <IwocaLogo />
      </div>
      <div className={styles.TokenWrapper}>
        <div className={styles.TokenEntry} data-testid={props["data-testid"]}>
          {props.children}
        </div>
        <div className={styles.contactInfo}>
          <Trans>
            If this keeps happening, please{" "}
            <a
              className={styles.contactLink}
              href="emailto:support@iwoca.co.uk"
            >
              email us
            </a>{" "}
            or give us a ring on{" "}
            <a
              className={styles.contactLink}
              href={i18n._(t`tel:+4420 3397 337`)}
            >
              020 3397 337
            </a>
          </Trans>
        </div>
      </div>
    </>
  );
};

export default TokenContainer;
