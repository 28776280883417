import React, {
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useState,
} from "react";

import { fetchPostIwocapayNotifications } from "@iwoca/lapi-client/edge";
import { Input } from "@iwoca/orion";
import classNames from "classnames";
import { Formik, FormikValues, useField } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { usePost } from "../../../../hooks/usePost";
import { TCopy, TStyles } from "../../../../lib/login/Login";
import {
  useSetupMultifactorAuth,
  useVerifyMultifactorAuth,
} from "../../hooks/useConnectMultifactorAuthentication";
import { useMultifactorAuthKey } from "../../hooks/useMultifactorAuthKey";
import styles from "../../MultifactorAuth.module.css";
import { parseError } from "../../utils/ErrorHandling";

export const OtpCodeForm = ({
  onSuccess,
  type,
  authToken,
  children,
}: {
  onSuccess: (state_key: string) => void;
  type: "sign up" | "log in";
  authToken?: string;
  children(onClick: () => void): ReactElement;
}) => {
  const { multifactorAuthKey } = useMultifactorAuthKey(authToken);
  const { post: submitNotification } = usePost(fetchPostIwocapayNotifications);
  const { post: submitLoginAuthentication } = useVerifyMultifactorAuth({
    authKey: multifactorAuthKey!,
  });
  const { post: submitSetupAuthentication } = useSetupMultifactorAuth({
    authKey: multifactorAuthKey!,
  });
  const [userAuthError, setUserAuthError] = useState<{
    code: string;
    message: string;
    loginButton: boolean;
  } | null>(null);

  const authoriseUser = useCallback(async (values: FormikValues) => {
    try {
      if (type === "log in") {
        const { data } = await submitLoginAuthentication({
          data: {
            otp: values.otpCode,
            remember_device: values.remember_device || false,
          },
        });
        if ("state_key" in data) onSuccess(data.state_key);
      }

      if (type === "sign up") {
        const { data } = await submitSetupAuthentication({
          data: {
            otp: values.otpCode,
          },
        });
        if ("state_key" in data) onSuccess(data.state_key);
      }
    } catch (error: any) {
      const parsedError = parseError(error.message);

      if (parsedError?.code === "InvalidOTP") {
        setUserAuthError({
          ...parsedError,
          message: "Your one time passcode is incorrect.",
          loginButton: false,
        });

        submitNotification({
          data: {
            type: "2fa-otp-invalid",
            attributes: { authToken: multifactorAuthKey },
          },
        });
        return;
      }

      if (parsedError?.code === "TokenExpired") {
        setUserAuthError({
          ...parsedError,
          message: "Your login session has expired. Please log in again.",
          loginButton: true,
        });
        return;
      }

      if (parsedError?.code === "TwoFactorSetupAlreadyCompleted") {
        setUserAuthError({
          ...parsedError,
          message: "You have already completed setup. Please log in.",
          loginButton: true,
        });
        return;
      }

      setUserAuthError({
        ...parsedError!,
        message: "An error occured, Please log in again.",
        loginButton: true,
      });
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={{ otpCode: "" }}
        onSubmit={authoriseUser}
        validationSchema={otpCodeValidation}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {children(() => {
              if (userAuthError) setUserAuthError(null);
            })}
            {userAuthError && (
              <p className={styles.validationError}>{userAuthError.message}</p>
            )}
            {userAuthError?.loginButton && (
              <Link to="/login" className={styles.link}>
                <p>Click here to log in</p>
              </Link>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export const OtpCodeField = ({
  name,
  styles: customStyles,
  copy,
  ...inputProps
}: {
  name: string;
  styles?: TStyles["mfa"];
  copy?: TCopy["mfa"];
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [field, { error, touched }] = useField(name);
  return (
    <>
      {/* @ts-expect-error - bad orion types */}
      <Input
        wrapperClassName={customStyles?.inputWrapper}
        className={classNames(
          styles.authenticatorCodeSetup,
          customStyles?.input,
        )}
        placeholder={copy?.placeholder || "Enter 6-digit authenticator code"}
        {...inputProps}
        {...field}
      />
      {error && touched && <p className={styles.validationError}>{error}</p>}
    </>
  );
};

const otpCodeValidation = () => {
  return Yup.object().shape({
    otpCode: Yup.string()
      .required("Your authenticator code is required")
      .matches(/^[0-9]+$/, "Your authenticator code must contain only digits")
      .length(6, "Your authenticator code must contain 6 digits"),
  });
};
