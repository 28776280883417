import React, { useState, useCallback } from "react";

import { AlertBox, Button, Input, Label } from "@iwoca/orion";
import { Trans } from "@lingui/react/macro";
import { Link } from "react-router-dom";

import greenCheckSVG from "../../../assets/checkmark.svg";
import { usePostApi } from "../../../fetchApi.hook";
import styles from "../../CommonLogin.module.css";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import LoginContainer from "../../LoginContainer";
import { isValidEmail } from "../../LoginHelpers";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const { status, hasError, isLoading, triggerFetch } = usePostApi({
    endpoint: "/api/lending/edge/password/reset/",
  });

  const handleForgotPassword = useCallback(
    (event) => {
      event.preventDefault();
      const isEmailValid = isValidEmail(email);
      if (!isEmailValid) {
        setIsEmailInvalid(true);
      }

      if (isEmailValid) triggerFetch({ data: { email } });
    },
    [email, triggerFetch],
  );

  return (
    <LoginContainer>
      <form
        className={styles.loginWithPassword}
        onSubmit={handleForgotPassword}
      >
        <Label htmlFor="email-input" className={styles.loginLabel}>
          <Trans>Email</Trans>
        </Label>
        <Input
          className="fs-exclude"
          data-testid="email-input"
          placeholder="name@provider.com"
          id="email-input"
          onChange={(event) => {
            setIsEmailInvalid(false);
            setEmail(event.target.value);
          }}
          onBlur={() => {
            if (!isValidEmail(email)) {
              setIsEmailInvalid(true);
            }
          }}
        />
        {isEmailInvalid ? (
          <AlertBox
            variant="failure"
            className={styles.errorBox}
            data-testid="error-message"
          >
            <Trans>Are you sure? This is not a valid email address</Trans>
          </AlertBox>
        ) : null}

        <p className={styles.instructions}>
          <Trans>
            Enter your email and we will send a link to reset your password.
          </Trans>
        </p>

        <Button
          variant="primary"
          disabled={isEmailInvalid || isLoading}
          type="submit"
          className={styles.submitButtonFull}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : !hasError && status === 204 ? (
            <Trans>Retry</Trans>
          ) : (
            <Trans>Reset my password</Trans>
          )}
        </Button>

        {!hasError && status === 204 ? (
          <AlertBox
            variant="success"
            className={styles.goodBox}
            data-testid="error-message"
          >
            <img src={greenCheckSVG} className={styles.checkmark} alt="" />
            <Trans>Check your email inbox; we've sent a link</Trans>
          </AlertBox>
        ) : null}

        {hasError && status !== 204 ? (
          <AlertBox variant="failure" data-testid="error-message">
            <Trans>Sorry, something’s gone wrong. Try again later?</Trans>
          </AlertBox>
        ) : null}

        <div className={styles.border}></div>

        <div className={styles.linkCopy} data-testid="link-to-password">
          <Trans>
            Or{" "}
            <Link className={styles.linkButton} to="/login/passwordless/email">
              email me a login link
            </Link>{" "}
            instead.
          </Trans>
        </div>
      </form>
    </LoginContainer>
  );
};

export default ForgotPassword;
