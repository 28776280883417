import React, { useState } from "react";

import { Button } from "@iwoca/orion";
import cn from "classnames";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

import copySVG from "../../../assets/copy.svg";
import { InformationBanner } from "../Components/InformationBanner/InformationBanner";
import {
  OtpCodeField,
  OtpCodeForm,
} from "../Components/OtpCodeForm/OtpCodeForm";
import { SetupStep } from "../Components/SetupStep/SetupStep";
import { useGetSetupMultifactorAuth } from "../hooks/useConnectMultifactorAuthentication";
import { useIsMobile } from "../hooks/useIsMobile";
import { useMultifactorAuthKey } from "../hooks/useMultifactorAuthKey";
import styles from "../MultifactorAuth.module.css";
import { SuccessMultifactorAuth } from "../SuccessMultifactorAuth/SuccessMultifactorAuth";
import { TwoFactorErrorResponse } from "../utils/ErrorHandling";

export const ConnectMultifactorAuth = () => {
  const { isMobile } = useIsMobile();
  const [shouldUseQRCode, setShouldUseQRCode] = useState<boolean>(!isMobile);
  const [isSetup, setIsSetup] = useState<boolean>(false);
  const { multifactorAuthKey } = useMultifactorAuthKey();
  const { setupData, isSetupError, setupLoading, setupError } =
    useGetSetupMultifactorAuth({ authKey: multifactorAuthKey! });

  if (setupLoading) {
    return null;
  }

  if (isSetupError) {
    return <ErrorScreen error={setupError!} />;
  }

  if (isSetup) {
    return <SuccessMultifactorAuth />;
  }

  const QRCodeOption = () => {
    return (
      <>
        <QRCode
          value={setupData!.data.setup_uri}
          bgColor={"rgba(255,255,255,0)"}
          className={styles.qrCode}
        />
        <p className={styles.qrCodeOtherOption}>
          Can’t scan the QR code?{" "}
          <button
            className={styles.inlineButton}
            onClick={() => setShouldUseQRCode(!shouldUseQRCode)}
          >
            Enter a setup key instead
          </button>
        </p>
      </>
    );
  };

  const SetupKeyOption = () => {
    return (
      <>
        <div className={styles.setupKeyTable}>
          <div className={styles.setupKeyTableRow}>
            <div className={styles.setupKeyTableHeader}>Account Email</div>
            <div className={styles.setupKeyTableBody}>
              {setupData!.data.account}
            </div>
          </div>
          <button
            className={styles.copyButton}
            onClick={() =>
              navigator.clipboard.writeText(setupData!.data.account)
            }
          >
            Copy <img src={copySVG} className={styles.copySVG} alt="" />
          </button>
          <div className={styles.setupKeyTableRow}>
            <div className={styles.setupKeyTableHeader}>Setup Key</div>
            <div className={styles.setupKeyTableBody}>
              {setupData!.data.key}
            </div>
          </div>
          <button
            className={styles.copyButton}
            onClick={() => navigator.clipboard.writeText(setupData!.data.key)}
          >
            Copy <img src={copySVG} className={styles.copySVG} alt="" />
          </button>
        </div>
        <button
          className={cn(styles.inlineButton, styles.qrCodeOtherOption)}
          onClick={() => setShouldUseQRCode(!shouldUseQRCode)}
        >
          Scan a QR code instead
        </button>
      </>
    );
  };

  return (
    <div className={styles.modalLarge}>
      <h2 className={styles.headlineMedium}>Set up security</h2>
      <SetupStep
        stepNumber={1}
        stepText="Open your authenticator app"
      ></SetupStep>
      <InformationBanner type="notice">
        <b>Don’t have an “authenticator app?”</b> - We’d recommend downloading{" "}
        <a
          href="https://googleauthenticator.net/"
          target="blank"
          rel="nofollow"
          className={styles.link}
        >
          Google Authenticator
        </a>{" "}
        or{" "}
        <a
          href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
          target="blank"
          rel="nofollow"
          className={styles.link}
        >
          Microsoft Authenticator
        </a>
        . It doesn’t cost a penny and you can use them to add extra security to
        your other online accounts.
      </InformationBanner>
      <SetupStep
        stepNumber={2}
        stepText={
          isMobile
            ? "Enter these details into your authenticator app."
            : "Scan the QR code with your authenticator app"
        }
        stepSubtitle="This will add your iwocaPay account to your authenticator app"
      >
        <div className={styles.informationBox}>
          {shouldUseQRCode ? <QRCodeOption /> : <SetupKeyOption />}
        </div>
      </SetupStep>
      <OtpCodeForm onSuccess={() => setIsSetup(true)} type="sign up">
        {(onClick: () => void) => (
          <>
            <SetupStep
              stepNumber={3}
              stepText="Enter your 6-digit code"
              stepSubtitle={`You'll see it under “iwocaPay ${
                setupData!.data.account
              }”`}
              stepBody={
                <div className={styles.pushField}>
                  <OtpCodeField onClick={onClick} name="otpCode"></OtpCodeField>
                </div>
              }
            ></SetupStep>
            <Button type="submit" className={styles.submitButtonShortSetup}>
              Confirm
            </Button>
          </>
        )}
      </OtpCodeForm>
    </div>
  );
};

const ErrorScreen = ({ error }: { error?: TwoFactorErrorResponse }) => {
  return (
    <div className={cn(styles.modalLarge, styles.errorModal)}>
      <h2 className={styles.somethingWrongTitle}>Something's gone wrong</h2>

      {error?.code === "TokenExpired" && <p>Your login session has expired</p>}

      <p className={styles.errorReason}>
        Sorry, we are unable to setup two factor authentication at this time.
        Please log in again and try again.
      </p>
      <Link to="/login" className={styles.link}>
        <Button
          type="submit"
          className={cn(styles.submitButton, styles.somethingWrongButton)}
        >
          Log in again
        </Button>
      </Link>
    </div>
  );
};
