import { useState, FormEvent } from "react";

import {
  fetchPostIwocapayNotifications,
  fetchPostPasswordlessEmail,
} from "@iwoca/lapi-client/edge";
import { LapiError } from "@iwoca/lapi-client/lendingAPI";
import { AlertBox, Button, Input, Label } from "@iwoca/orion";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { getRedirectToLink } from "./getRedirectLink";
import greenCheckSVG from "../../assets/checkmark.svg";
import { usePost } from "../../hooks/usePost";
import { LoginErrorMessage } from "../../lib/LoginErrorMessage/LoginErrorMessage";
import styles from "../CommonLogin.module.css";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import LoginContainer from "../LoginContainer";
import { isValidEmail } from "../LoginHelpers";
import {
  determineTwoFactorRedirect,
  reportIwocaPayBrokenAccount,
} from "../MultifactorAuth/utils/ErrorHandling";

const LoginWithLink = () => {
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const { post: submitNotification } = usePost(fetchPostIwocapayNotifications);
  const { search } = useLocation();
  const navigate = useNavigate();
  const { post: login, isLoading, error } = usePost(fetchPostPasswordlessEmail);
  const [success, setSuccess] = useState<boolean>(false);

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      setIsEmailInvalid(true);
    }

    if (isEmailValid) {
      try {
        await login({
          data: { email, redirect_to: getRedirectToLink() },
        });
        setSuccess(true);
      } catch (e) {
        const lapiError = e as LapiError;
        reportIwocaPayBrokenAccount({ lapiError, email, submitNotification });
        determineTwoFactorRedirect({
          lapiError,
          shouldRedirect: (url) => {
            navigate(url);
          },
        });
      }
    }
  };

  return (
    <LoginContainer>
      <form
        className={styles.loginWithLinkForm}
        onSubmit={handleLogin}
        data-testid="login-link-form"
      >
        <Label htmlFor="email-input" className={styles.loginLabel}>
          <Trans>Email</Trans>
        </Label>
        <Input
          className={`${styles.fieldInputs} fs-exclude`}
          data-testid="email-input"
          placeholder="name@provider.com"
          id="email-input"
          onChange={(event) => {
            setIsEmailInvalid(false);
            setEmail(event.target.value);
          }}
          onBlur={() => {
            if (!isValidEmail(email)) {
              setIsEmailInvalid(true);
            }
          }}
        />
        {isEmailInvalid ? (
          <AlertBox
            variant="failure"
            className={styles.errorBox}
            data-testid="error-message"
          >
            <Trans>Are you sure? This is not a valid email address</Trans>
          </AlertBox>
        ) : null}

        <Button
          disabled={isEmailInvalid || isLoading}
          type="submit"
          variant="primary"
          className={styles.submitButton}
        >
          {isLoading ? (
            <LoadingSpinner data-testid="confirm-button-loading" />
          ) : success ? (
            <Trans>Retry</Trans>
          ) : (
            <Trans>Send me a login link</Trans>
          )}
        </Button>

        {success ? (
          <AlertBox
            variant="success"
            className={styles.goodBox}
            data-testid="error-message"
          >
            <img src={greenCheckSVG} className={styles.checkmark} alt="" />
            <Trans>Check your email inbox; we've sent a link</Trans>
          </AlertBox>
        ) : null}

        {error ? (
          <LoginErrorMessage
            error={error}
            copy={{
              loginEmailOrPassWrong: t`The email or password you entered is incorrect`,
              loginError: t`Sorry, something’s gone wrong. Try again later?`,
            }}
          />
        ) : null}

        <span className={styles.border}></span>

        <span className={styles.linkCopy} data-testid="link-to-password">
          <Trans>
            Or{" "}
            <Link className={styles.linkButton} to={`/login/password${search}`}>
              login with password
            </Link>{" "}
            instead.
          </Trans>
        </span>
      </form>
    </LoginContainer>
  );
};

export default LoginWithLink;
