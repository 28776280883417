import React, { useEffect, useState } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import styles from "./App.module.css";
import loaderGif from "./assets/TokenAssets/iwoca-loading.gif";
import { useAmplitudeTracking } from "./hooks/useAmplitudeTracking/useAmplitudeTracking";
import { redirectUser } from "./Login/authentication";
import { getRedirectToLink } from "./Login/LoginWithLink/getRedirectLink";
import LoginTokenVerification from "./Login/LoginWithLink/LoginTokenVerification";
import LoginWithLink from "./Login/LoginWithLink/LoginWithLink";
import ForgotPassword from "./Login/LoginWithPassword/ForgotPassword/ForgotPassword";
import LoginWithPassword from "./Login/LoginWithPassword/LoginWithPassword";
import ResetPassword from "./Login/LoginWithPassword/ResetPassword/ResetPassword";
import { AuthenticateMultifactorAuth } from "./Login/MultifactorAuth/AuthenticateMultifactorAuth/AuthenticateMultifactorAuth";
import { ConnectMultifactorAuth } from "./Login/MultifactorAuth/ConnectMultifactorAuth/ConnectMultifactorAuth";
import { MultifactorAuth } from "./Login/MultifactorAuth/MultifactorAuth";
import { SetupMultifacatorAuth } from "./Login/MultifactorAuth/SetupMultifactorAuth/SetupMultifacatorAuth";
import StaffForgotPassword from "./Login/StaffForgotPassword/StaffForgotPassword";
import TokenContainer from "./Login/TokenViews/TokenContainer";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: false,
        retryOnMount: false,
      },
    },
  });
  const [isCheckingLoggedIn, setIsCheckingLoggedIn] = useState(true);
  useEffect(() => {
    async function checkLoggedIn() {
      try {
        await redirectUser({
          isTestingLoggedIn: true,
          redirectUrl: getRedirectToLink(),
        });
      } catch (err) {
        setIsCheckingLoggedIn(false);
      }
    }
    if (
      /(password|passwordless)\/(set|reset|email)\/(.+)/.test(
        window.location.pathname,
      ) ||
      /\/login\/(two-factor)\/(authenticate|setup)\/(.*)/.test(
        window.location.pathname,
      )
    ) {
      setIsCheckingLoggedIn(false);
    } else {
      checkLoggedIn();
    }
  }, []);

  useAmplitudeTracking();

  if (isCheckingLoggedIn) {
    return (
      <TokenContainer>
        <img className={styles.loadingGif} src={loaderGif} alt="loading" />
      </TokenContainer>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div data-testid="app">
        <Routes>
          <Route path="/login/passwordless/email" element={<LoginWithLink />} />
          <Route
            path="/login/passwordless/email/:token"
            element={<LoginTokenVerification />}
          />
          <Route
            path="/login/password/reset/staff"
            element={<StaffForgotPassword />}
          />

          {/*Todo: setup child paths, to avoid replication*/}
          <Route
            path="/login/two-factor/setup"
            element={
              <MultifactorAuth>
                <SetupMultifacatorAuth />
              </MultifactorAuth>
            }
          />
          <Route
            path="/login/two-factor/setup/connect"
            element={
              <MultifactorAuth>
                <ConnectMultifactorAuth />
              </MultifactorAuth>
            }
          />
          <Route
            path="/login/two-factor/authenticate"
            element={
              <MultifactorAuth>
                <AuthenticateMultifactorAuth />
              </MultifactorAuth>
            }
          />

          <Route path="/login/password" element={<LoginWithPassword />} />
          <Route path="/login/password/reset" element={<ForgotPassword />} />
          <Route
            path="/login/password/reset/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/login/password/set/:token"
            element={<ResetPassword />}
          />

          <Route
            path="/login/*"
            element={<Redirect to="/login/passwordless/email" />}
          />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

const Redirect = ({ to }: { to: string }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = window.location.search ? `?${params}` : "";
    navigate(`${to}${query}`);
  });

  return null;
};

export default App;
