import React from "react";

import { Trans } from "@lingui/react/macro";
import { Link } from "react-router-dom";

import TokenContainer from "./TokenContainer";
import styles from "./TokenContainer.module.css";
import TokenExpiredSVG from "../../assets/TokenAssets/TokenExpiredSVG";

type Props = {
  ctaUrl: string;
};
const TokenExpired: React.FunctionComponent<Props> = ({ ctaUrl }) => {
  return (
    <TokenContainer data-testid="token-expired-view">
      <TokenExpiredSVG className={styles.tokenIllustration} />

      <h1 className={styles.tokenResponseTitle}>
        <Trans>Uh oh, time's up</Trans>
      </h1>

      <div className={styles.tokenReponseDescription}>
        <Trans>It's been more than an hour, so your link's expired.</Trans>
      </div>

      <Link to={ctaUrl} className={styles.tokenResponseCTA}>
        <Trans>Start again with a new link</Trans>
      </Link>
    </TokenContainer>
  );
};

export default TokenExpired;
