import { useState, useCallback } from "react";

import { getCookie } from "../../cookieHelper";

type useFetchApiArgs = {
  endpoint: string;
  fetchConfig?: RequestInit;
  initFetch?: boolean;
};
export type SuccessResponse = { success: true };
export type ErrorResponse = { email: { message: string; code: string }[] };
type FetchApiOutput = {
  status?: number;
  payload: SuccessResponse | ErrorResponse | undefined;
  hasError: boolean;
};
type useFetchApiOutput = FetchApiOutput & {
  isLoading: boolean;
  triggerFetch: (data: object) => Promise<FetchApiOutput>;
};
export const useResetPasswordApi = ({
  endpoint,
}: useFetchApiArgs): useFetchApiOutput => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [status, setStatus] = useState<number | undefined>();
  const [payload, setPayload] = useState();

  const fetchData = useCallback(
    async ({ email }): Promise<FetchApiOutput> => {
      setLoading(true);

      const response = await fetch(endpoint, {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken") as string,
        },
        body: `email=${email}`,
      });
      const responseStatus = response.status;
      setStatus(responseStatus);

      let responsePayload;
      try {
        responsePayload = await response.json();
      } catch (error) {
        if (!response.ok) {
          setHasError(true);
          setLoading(false);
          responsePayload = error;
          return {
            hasError: true,
            payload: undefined,
            status: responseStatus,
          };
        }
      }
      setPayload(responsePayload);

      if (!response.ok) {
        setHasError(true);
        setLoading(false);
        return {
          hasError: true,
          payload: undefined,
          status: responseStatus,
        };
      }

      setLoading(false);
      return {
        payload: responsePayload,
        hasError: false,
        status: responseStatus,
      };
    },
    [endpoint],
  );

  return {
    isLoading,
    status,
    payload,
    hasError,
    triggerFetch: fetchData,
  };
};
