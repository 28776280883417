import { useCallback } from "react";

import {
  fetchGetTwoFactorSetup,
  fetchPostTwoFactorAuthenticate,
  fetchPostTwoFactorSetup,
  PostTwoFactorAuthenticateRequestBody,
} from "@iwoca/lapi-client/edge";
import { useQuery } from "react-query";

import { usePost } from "../../../hooks/usePost";
import { NetworkError, parseError } from "../utils/ErrorHandling";

export const useVerifyMultifactorAuth = ({ authKey }: { authKey: string }) => {
  const postTwoFactorAuthenticate = useCallback(
    ({ body }: { body: PostTwoFactorAuthenticateRequestBody }) => {
      return fetchPostTwoFactorAuthenticate({
        authenticationToken: authKey,
        body,
      });
    },
    [authKey],
  );

  const {
    post,
    isLoading,
    error: lapiError,
  } = usePost(postTwoFactorAuthenticate);

  const error = lapiError ? parseError(lapiError.message) : null;

  return {
    isLoading,
    error,
    post,
  };
};

export const useSetupMultifactorAuth = ({ authKey }: { authKey: string }) => {
  const postTwoFactorSetup = useCallback(
    ({ body }: { body: PostTwoFactorAuthenticateRequestBody }) => {
      return fetchPostTwoFactorSetup({
        authenticationToken: authKey,
        body,
      });
    },
    [authKey],
  );

  const { post, isLoading, error: lapiError } = usePost(postTwoFactorSetup);

  const error = lapiError ? parseError(lapiError.message) : null;

  return {
    isLoading,
    error,
    post,
  };
};

export const useGetSetupMultifactorAuth = ({
  authKey,
}: {
  authKey: string;
}) => {
  const {
    isLoading: setupLoading,
    data: setupData,
    isError: isSetupError,
    error: setupError,
  } = useQuery({
    queryKey: ["fetchGetApplication"],
    queryFn: () => fetchGetTwoFactorSetup({ authenticationToken: authKey }),
    retry: false,
    staleTime: Infinity,
  });

  return {
    setupLoading,
    setupData,
    isSetupError,
    setupError: parseError((setupError as NetworkError)?.message),
  };
};
