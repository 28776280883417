/** @jsxRuntime classic */
import "@iwoca/orion/lib/css/orion.css";
import React from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { isIwocaDE } from "./countryHelper";
import { dynamicActivate } from "./il8n";
import "./index.css";

export const getRuntimeEnvironment = () =>
  window.location.host.includes("stage") ? "staging" : "production";
export const getRuntimeCountry = () =>
  window.location.host.includes(".de") ? "de" : "uk";
const isProductionBuild = import.meta.env.MODE === "production";
const environment = getRuntimeEnvironment();

if (isProductionBuild) {
  datadogLogs.init({
    clientToken:
      environment === "production"
        ? "pubc296c5cdf9541efc5f360dd77e7be746"
        : "pub6175b0f8bbecb2be93245c1e885bee9a",
    site: "datadoghq.com",
    service: "login-ui",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: environment,
    version: import.meta.env.VITE_RELEASE,
  });
}

const I18nApp = () => {
  React.useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(isIwocaDE() ? "de" : "en");
  }, []);

  return (
    <BrowserRouter>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <I18nApp />
  </React.StrictMode>,
  document.getElementById("root"),
);
